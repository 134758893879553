import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/HomeLayout.jsx";
import { Link } from 'gatsby';
import CVRow from '@components/CVRow/CVRow';
import { cvData } from '@data/cvData';
import * as styles from '@styles/pages/cv.module.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <div className={styles.cv}>
      <h1>{`cv`}</h1>
      <div className={styles.fadeInChildren}>
        <h2>{`Curriculum Vitae`}</h2>
        <p><em parentName="p">{`Note: abbreviated CV; `}<a parentName="em" {...{
              "href": "/contact"
            }}>{`get in touch`}</a>{` for a more complete pdf verison.`}</em></p>
        <hr></hr>
        <h2>{`Employment`}</h2>
        <table className={styles.cvTable}>
  {cvData.employment.map(data => <CVRow {...data} mdxType="CVRow" />)}
        </table>
        <h2>{`Education`}</h2>
        <table className={styles.cvTable}>
  {cvData.education.map(data => <CVRow {...data} mdxType="CVRow" />)}
        </table>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      